$dirty-white: #f9fbfe;
$color-black: #000000;
$color-white: #fff;
$color-green: #005450;
$color-gray: #4a4a4a;
$color-gold: #fe9400;
$color-graylite: #c4c4c4;
$size-m: 0.935rem;
$size-l: 1.25rem;
$max-width-lg: 1180px;
$screen-xl: 1200px;
$screen-lg: 1024px;
$screen-md: 768px;

button {
  border: none;
  background: none;
  font-family: "DM Sans", sans-serif;
  &.link {
    &:first-child {
      margin-right: 16px;
    }
    font-size: $size-m;
    font-weight: 500;
    color: $color-black;
    letter-spacing: -0.3px;
    &.button {
      color: $color-white;
      background-color: $color-green;
      padding: $size-m * 0.75;
      border-radius: 8px;
    }
  }
  &.secondary {
    color: $color-green;
    padding: $size-m;
    font-weight: 700;
    border-radius: $size-m;
    background-color: $color-white;
  }
}

.card {
  .box {
    width: 60px;
    height: 60px;
    // background: $color-graylite;
    border-radius: $size-m;
    margin-bottom: $size-m;
  }
  .c-title {
    font-weight: 700;
    font-size: $size-l;
    line-height: 31px;
    margin: 0.5rem 0;
  }
  .c-details {
    max-width: 280px;
    font-size: $size-m;
    color: $color-gray;
  }
}

h2.title {
  text-align: center;
  font-size: 36px;
  margin: 0;
  margin-bottom: $size-l;
  color: $color-black;
}

.hide-sm {
  @media (max-width: $screen-lg - 1) {
    display: none;
  }
}

.hide-lg {
  @media (min-width: $screen-lg) {
    display: none;
  }
}

.show-xl-only {
  @media (max-width: $screen-xl - 1) {
    display: none;
  }
}

.landing-container {
  width: 100%;
  background-color: $dirty-white;
  background-image: url("../../assets/topcorner.png");
  background-repeat: no-repeat;
  background-position: top right;
  .nav-bar {
    width: 94%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: $size-m;
    padding: 1rem 0.5rem;
    @media (min-width: $screen-lg) {
      padding: 1rem 0;
      max-width: $max-width-lg - 18px; // for the extra invisible-space created by banner img;
    }
    .logo {
      img {
        @media (max-width: $screen-lg - 1) {
          height: 28px;
        }
      }
    }
    .links {
      display: flex;
      .link {
        @media (max-width: $screen-lg - 1) {
          font-size: 0.75rem;
          &:first-child {
            margin-right: 8px;
          }
          &.button {
            padding: 0.6rem 0.4rem;
          }
        }
      }
    }
  }
  .banner {
    width: 90%;
    margin: 0 auto;
    @media (min-width: $screen-lg) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    @media (min-width: $screen-xl) {
      width: 100%;
      max-width: $max-width-lg;
    }
    &-title {
      margin-bottom: 4rem; // to cover up for annoying empty space
      padding: 2.5rem 1.5rem;
      @media (min-width: $screen-lg) {
        padding: 0;
      }
      .title {
        font-size: 54px;
        font-weight: 700;
        margin: 0;
        @media (min-width: $screen-lg) {
          text-align: left;
          font-size: 80px;
        }
        span {
          color: $color-green;
          display: block;
        }
      }
      .sub-title {
        font-size: $size-l;
        color: $color-gray;
      }
    }
    .mobile-view {
      @media (max-width: $screen-lg) {
        display: flex;
        justify-content: center;
        svg {
          width: 98%;
          height: 100%;
          max-width: 400px;
        }
      }
    }
  }
  .key-features {
    width: 100%;
    max-width: $max-width-lg;
    margin: 2rem auto;
    display: flex;
    justify-content: space-between;
    position: relative;
    @media (max-width: $screen-xl - 1) {
      display: none;
      width: 90%;
    }
    .hook {
      .content {
        &.left {
          position: absolute;
          left: 0;
          bottom: 15%;
          img.hookline {
            position: absolute;
            left: 320px;
            top: 40px;
          }
        }
        &.right {
          position: absolute;
          right: 0;
          top: 35%;
          img.hookline {
            position: absolute;
            right: 300px;
          }
        }
      }
    }
  }
  .many-more {
    width: 100%;
    padding: 2rem 0;
    @media (min-width: $screen-lg) {
      width: 90%;
      margin: auto;
    }
    @media (min-width: $screen-xl - 1) {
      max-width: $max-width-lg;
    }
    .cards {
      margin: 3rem 0;
      padding: 1rem;
      @media (min-width: $screen-md) {
        display: flex;
        justify-content: space-between;
        padding: 1.5rem;
      }
      @media (min-width: $screen-lg) {
        display: flex;
        justify-content: space-between;
        padding: 0;
      }
      .card {
        margin-bottom: 3rem;
        @media (max-width: $screen-xl - 1) {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;
        }
        @media (min-width: $screen-lg) {
          margin-bottom: unset;
        }
        .c-details {
          line-height: $size-l;
        }
      }
    }
  }
  .download {
    background-color: $color-green;
    border-top-left-radius: $size-m;
    border-top-right-radius: $size-m;
    padding: 40px;
    @media (min-width: $screen-xl) {
      max-width: $max-width-lg - 220px;
      width: 90%;
      margin: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 100px;
      padding-bottom: 0;
    }
    .instruction {
      @media (min-width: $screen-xl) {
        max-width: 45%;
      }
      h2.title {
        text-align: left;
        color: #fff;
        font-size: $size-l * 2;

        @media (min-width: $screen-lg) {
          font-size: 48px;
        }
        span {
          color: $color-gold;
        }
      }
    }
    .transaction {
      position: relative;
      top: 8px;
      @media (max-width: $screen-lg) {
        display: flex;
        justify-content: center;
        margin-top: 1rem;
        position: relative;
        top: 2.5rem;
        img {
          width: 98%;
          height: 100%;
          max-width: 400px;
        }
      }
    }
  }
  .footer {
    width: 100%;
    background-color: $color-white;
    .container {
      padding: 5rem 0;
      @media (min-width: $screen-lg) {
        width: 100%;
        max-width: $max-width-lg;
        margin: auto;
        display: flex;
        justify-content: space-between;
      }
      .logo {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 2rem;
        @media (min-width: $screen-lg) {
          display: block;
        }
        img {
          height: 40px;
          max-width: 200px;
          margin-bottom: $size-m;
        }
        span {
          display: block;
          color: $color-gray;
          font-size: $size-l * 0.6;
        }
      }
      .contact {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        @media (min-width: $screen-lg) {
          display: block;
        }
        .socials {
          display: flex;
          justify-content: flex-start;
          a {
            display: block;
            margin-right: $size-l;
            img {
              width: 80%;
            }
          }
        }
      }
    }
  }
}

// utils.
.slide-in {
  position: relative;
  animation: slideIn 300ms cubic-bezier(0.4, 0, 0.2, 1);
}
@keyframes slideIn {
  from {
    transform: translateY(1rem);
  }

  to {
    transform: translateY(0);
  }
}
